import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/avatar.svg';
import Tilt from 'react-parallax-tilt';

import AlexLogo from '../../Assets/Corps/alex-logo.png';
import GoodbyeLogo from '../../Assets/Corps/goodbye-logo.png';
import JATTLogo from '../../Assets/Corps/jatt-logo.png';
import CCTVLogo from '../../Assets/Corps/cctv-logo.png';
import RivoloLogo from '../../Assets/Corps/rivolo-logo.png';
import VIPKidLogo from '../../Assets/Corps/vipkid-logo.png';
import AugmentumLogo from '../../Assets/Corps/augmentum-logo.png';
import XanaLogo from '../../Assets/Corps/xanamatrix-logo.png';

import { AiFillGithub, AiFillInstagram } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import CorpItem from './CorpItem';

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: '2.6em' }}>
              LET ME <span className='purple'> INTRODUCE </span> OURSELF
            </h1>
            <div className='home-about-body'>
              <b className='purple'>TigerTech Innovations</b> is a dynamic
              software development company, led by the talented and experienced
              Tiger Wang. With a wealth of knowledge and a passion for
              technology, TigerTech offers a comprehensive range of services to
              clients seeking development solutions.
              <br />
              <br />
              <b className='purple'>Custom Software Solutions:</b> We tailor our
              services to meet your unique needs, whether it's developing a
              bespoke enterprise application or integrating advanced
              technologies like AI or IoT into your existing systems.
              <br />
              <br />
              <Row
                xs={1}
                md={4}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <CorpItem icon={VIPKidLogo} title={'Solution Provider'} info={'Cross-platform multimedia classroom'} id={'vipkid'}/>
                </Col>
                <Col>
                  <CorpItem icon={CCTVLogo} title={'Solution Provider'} info={'Steam Video Player'} id={'cctv'}/>
                </Col>
                <Col>
                  <CorpItem icon={AugmentumLogo} title={'Solution Provider'} info={'CCTV Olympics 2008 website(off-line)'} id={'aug'}/>
                </Col>
              </Row>
              <br />
              <b className='purple'>Blockchain Technology:</b> With experience
              in developing DeFi apps and NFT projects, we offer
              blockchain-based solutions that leverage the power of smart
              contracts and cryptocurrencies.
              <br />
              <br />
              <Row
                md={4}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <CorpItem icon={AlexLogo}  title={'Developer'} info={'DApp dev solution provider.'} id={'alex'}/>
                </Col>
              </Row>
              <br />
              <b className='purple'>Mobile App Development:</b> Our team has
              expertise in creating engaging and functional mobile applications
              for iOS and Android platforms, utilizing native development tools
              or cross-platform frameworks like React Native or Flutter.
              <br />
              <br />
              <Row
                md={4}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <CorpItem icon={JATTLogo} title={'Co-Founder'} info={'Full stack solution provider.'} id={'jatt'}/>
                </Col>
              </Row>
              <b className='purple'>Game Development:</b> TigerTech has a
              dedicated game development division that crafts immersive gaming
              experiences. We work with Unity, SmartFox Server, and various game
              engines to develop games for web, mobile, and social media
              platforms.
              <br />
              <br />
              <Row
                md={4}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <CorpItem icon={RivoloLogo} title={'CTO'} info={'Game Dev.'} id={'rivolo'}/>
                </Col>
              </Row>
              <br />
              <b className='purple'>Full-Stack Development:</b> TigerTech
              specializes in building robust and scalable web applications using
              the latest technologies, including React, Solid, Node.js, and AWS.
              We create secure and user-friendly solutions tailored to your
              specific requirements.
              <br />
              <br />
              <Row
                xs={2}
                md={4}
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <CorpItem icon={GoodbyeLogo} title={'Solution Provider'} info={'goodbye.wtf'} id={'goodbye'}/>
                </Col>
                <Col>
                  <CorpItem icon={XanaLogo}  title={'Solution Provider'} info={'Flash Popular Science Game for China Digital Science Museum(off-line)'} id={'xana'}/>
                </Col>
              </Row>
              <br />
            </div>
          </Col>
          <Col md={4} className='myAvtar'>
            <Tilt>
              <img src={myImg} className='img-fluid' alt='avatar' />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className='purple'>connect </span>with me
            </p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/hecool108'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/hecool108/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.instagram.com/hecool108'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
