import React from 'react';
import Card from 'react-bootstrap/Card';

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: 'start' }}>
            Founded in 2008, TigerTech has since established itself as a leading
            provider of innovative software solutions. 
            <br/>
            <br/>
            The company boasts a
            diverse portfolio that showcases their expertise in various
            technologies and platforms. 
            <br/>
            <br/>
            With a strong focus on client
            satisfaction, TigerTech is committed to delivering high-quality,
            reliable, and cutting-edge products.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
