import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGoogleCloudPlatform,
  DiJava,
} from "react-icons/di";
import {
  SiSwift,
  SiSolidity,
  SiPostgresql,
  SiTypescript,
  SiCsharp,
  SiBun,
  SiHasura
} from "react-icons/si";
import { GrGraphQl } from "react-icons/gr";
import { FaAws } from "react-icons/fa";
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBun />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSwift />
      </Col>
      
      
      <Col xs={4} md={2} className="tech-icons">
        <SiSolidity />
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiGoogleCloudPlatform />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <GrGraphQl />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHasura />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
    </Row>
  );
}

export default Techstack;
