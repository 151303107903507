import React from 'react';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const CorpItem = ({ icon, title, info, id }) => {
  return (
    <OverlayTrigger
      key={id}
      overlay={
        <Tooltip id={`tooltip-${id}`}>
          <h5 style={{ textAlign: 'left' }}>{title}</h5>
          <div style={{ textAlign: 'left' }}>{info}</div>
        </Tooltip>
      }
    >
      <Image
        src={icon}
        style={{
          width: '120px',
        }}
      />
    </OverlayTrigger>
  );
};
export default CorpItem;
