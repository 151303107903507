import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import goodbye from "../../Assets/Projects/goodbye.png";
import alex from "../../Assets/Projects/alex.png";
import vipkid from "../../Assets/Projects/vipkid.png"


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects We've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={goodbye}
              isBlog={false}
              title="Goodbye NFT"
              description="A creative NFT project built on Ethereum, showcasing our full-stack development capabilities."
              ghLink="https://github.com/goodbye-wtf/goodbye-dapp"
              demoLink="https://goodbye.wtf/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={alex}
              isBlog={false}
              title="ALEX DeFi"
              description="A DeFi App running on Stacks Blockchain, where we played a pivotal role in front-end development and smart contract testing."
              ghLink="https://github.com/alexgo-io"
              demoLink="https://app.alexlab.co"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vipkid}
              isBlog={false}
              title="VIPKid"
              description="We led the development of a multi-platform video classroom App, resulting in significant achievements and awards."
              demoLink="https://www.vipkid.com/en-us/"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
